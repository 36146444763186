<template>
  <div id="Case">
    <h2>南京市上元中学</h2>
    <!-- <p>Nanjing Shangyuan Middle School</p> -->
    <div class="case-box">
      <p>
        方案定位于加快智慧校园建设与升级，实现校园能源全生命周期绿色运行管理，打造节约型校园、绿色学校。通过物联网技术及产品的应用，在区域层面解决上级主管部门对区域内各学校水电耗能需要实时掌握、主动预警、综合分析的现实需求；在校级层面解决学校水电能源费用支出持续上升，水电等能源浪费严重，校园违章用电隐患多、用电安全问题突出；管理精细化程度不高等问题。
      </p>
      <div class="button">方案优势</div>
      <ul>
        <li>
          <img
            src="../../../assets/images/partnersCase/cases/case5_item1.png"
            alt=""
          />
          <div class="right">
            方案契合国家建设绿色社会、绿色学校的倡导；帮助广大师生树立正确的节能意识、生态观；提升教育部门、学校绿色管理水平，增强绿色可持续发展理念，提高社会声誉。
          </div>
        </li>
        <li>
          <img
            src="../../../assets/images/partnersCase/cases/case5_item2.png"
            alt=""
          />
          <div class="right">
            基于窄带蜂窝物联网技术、区校双平台设计，云端+本地化部署，为学校提供节能降耗与改进优化的数据依据，为能源主管部门提供动态化的能源管理决策依据。
          </div>
        </li>
        <li>
          <img
            src="../../../assets/images/partnersCase/cases/case5_item3.png"
            alt=""
          />
          <div class="right">
            方案对校园内主要用能系统和设备进行效能优化；量化部门用能数据，明晰主体能耗管理责任；降低管网漏损率，减少故障率，保障校园供水、供电的安全性、可持续性；极大降低学校能耗成本促进节能减排。
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      margin: 50px 0;
    }
    .button {
      width: 270px;
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-radius: 10px;
      border: 2px solid $--color-primary;
      color: $--color-primary;
      margin: 60px auto;
    }
    li {
      width: 80%;
      margin: 0 auto 52px;
      display: flex;
      align-items: center;
      img {
        width: 116px;
        height: 116px;
      }
      .right {
        background: #f7f7f7;
        border-radius: 12px;
        text-align: justify;
        padding: 40px;
        box-sizing: border-box;
        font-size: 18px;
        color: #333333;
        line-height: 28px;
        margin-left: 45px;
      }
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      li {
        width: 90%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;
    p {
      font-size: 14px;
      line-height: 28px;
      margin: 30px 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 40px;
    li {
      flex-direction: column;
      width: 90%;
      img {
        width: 80px;
        height: 80px;
      }
      .right {
        padding: 20px;
        margin: 20px 0 0 0;
        font-size: 14px;
        line-height: 28px;
        color: #666;
      }
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
